<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  page: {
    title: "Nouveau secteur",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Nouveau secteur",
      items: [
        {
          text: "Infrastructures",
          href: "/",
        },
        {
          text: "Secteurs",
          href: "/",
        },
        {
          text: "Nouveau secteur",
          active: true,
        },
      ],
      zones: [],
      sectorForm: {
        sectName: "",
        sectZone: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    sectorForm: {
      sectName: { required, maxLength : maxLength(64) },
      sectZone: { required },
    },
  },
  methods: {
    fetchZonesList() {
      this.$http
        .post("/infrastructure/zones/list")
        .then((res) => (this.zones = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch()
      if(!this.$v.$invalid){
      var router = this.$router;
      this.$http
        .post("/infrastructure/sectors/store", this.sectorForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
                this.$toast.success(res.data.original.msg);
                router.push({ name: "base.infrastructures.sectors" });
              break;

            case 500:
                this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
      }
    },
  },

  mounted() {
    this.fetchZonesList();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="deptName">Désignation</label>
                    <input
                      id="deptName"
                      v-model="sectorForm.sectName"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value="Mark"
                      :class="{
                        'is-invalid': submitted && $v.sectorForm.sectName.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.sectorForm.sectName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.sectorForm.sectName.required"
                        >Le nom est obligatoire.</span
                      >
                      <span v-if="!$v.sectorForm.sectName.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom03">Zone</label>
                    <select
                      id="projectname"
                      v-model="sectorForm.sectZone"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.sectorForm.sectZone.$error,
                      }"
                      placeholder=""
                    >
                      <option value=""></option>
                      <option
                        v-for="zone in zones"
                        v-bind:value="zone.id"
                        v-bind:key="zone.id"
                      >
                        {{ zone.name }}
                      </option>
                    </select>
                    <div
                      v-if="submitted && $v.sectorForm.sectZone.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.sectorForm.sectZone.required"
                        >La zone est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary"
                type="submit"
              >
                Enregistrer
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
